import FingerprintJS from '@fingerprintjs/fingerprintjs';

async function getVisitorId() {
  let visitorId = localStorage.getItem('visitorId');
  if (!visitorId) {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    visitorId = result.visitorId;
    console.log('指纹',visitorId)
    setVisitorId(visitorId);
  }
  return visitorId;
}

function setVisitorId(visitorId) {
  localStorage.setItem('visitorId', visitorId);
}

export { getVisitorId };