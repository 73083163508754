import ErrorStackParser from 'error-stack-parser';
import getClientType from '@/hooks/getclientinfo'
import {
    send
} from '@/hooks/datareport'

export function errMonitor(app) {
    if (process.env.NODE_ENV === "development") return;
    app.config.errorHandler = (e) => {
        console.log('vue错误', e)
        let errObj = {}
        if (e instanceof Error) {
            const ErrorInfo = ErrorStackParser.parse(e)
            if (ErrorInfo.length > 0) {
                errObj = {
                    errInfo: 'Vue错误:'+String(e),
                    errSource: ErrorInfo[0].fileName,
                    errLineno: ErrorInfo[0].lineNumber,
                    errColno: ErrorInfo[0].columnNumber,
                    errUrl: window.location.pathname,
                    errTime: new Date().getTime(),
                    clientInfo: getClientType()
                }
            } else {
                return;
            }

        }else{
            return;
        }
        // 向追踪服务报告错误
        send('Error', errObj)
    }
    window.addEventListener('unhandledrejection', e => {
        console.log('未处理的promise错误', e.reason)
        let errObj = {}
        const ErrorInfo = ErrorStackParser.parse(e.reason)
        if(e instanceof Error){
            if(ErrorInfo.length > 0){
                 errObj = {
                    errInfo: 'Promise错误:'+ e.reason.message,
                    errSource: ErrorInfo[0].fileName,
                    errLineno: ErrorInfo[0].lineNumber,
                    errColno: ErrorInfo[0].columnNumber,
                    errUrl: window.location.pathname,
                    errTime: new Date().getTime(),
                    clientInfo: getClientType()
                }
            }else{
                return;
            }
        }else{
            return;
        }
        console.log('未处理的promise错误', errObj)
        send('Error', errObj)
    })
    window.addEventListener('error', (e) => {
        console.log('资源加载错误', e)
        let errObj = null
        if (e.colno) {
            const ErrorInfo = ErrorStackParser.parse(e.error)
            if(e instanceof Error){
                if(ErrorInfo.length > 0){
                    errObj = {
                        errInfo: 'JS错误:' + e.error.message?e.error.message:JSON.stringify(e),
                        errSource: ErrorInfo[0].fileName,
                        errLineno: ErrorInfo[0].lineNumber,
                        errColno: ErrorInfo[0].columnNumber,
                        errUrl: window.location.pathname,
                        errTime: new Date().getTime(),
                        clientInfo: getClientType()
                    }
                }else{
                    return;
                }
            }else{
                return;
            }
            
        } else {
            //图片资源排除 或图片资源加载失败截取网页
            if (e.target['localName'] === 'img') return;
            errObj = {
                errInfo: '资源错误:' + e.target['localName']?e.target['localName']:JSON.stringify(e),
                errSource: e.target['href'] || e.target['currentSrc'] || e.target['src'],
                errLineno: 0,
                errColno: 0,
                errUrl: window.location.pathname,
                errTime: new Date().getTime(),
                clientInfo: getClientType()
            }
        }
        // console.log('资源加载错误：', errObj)
        send('Error', errObj)
    }, true)
}