<template>
  <div class="main_container">
    <Header
      :city="city"
      :temperature="temperature"
      :weather="weather"
      :weatherBg="weatherBg"
    ></Header>
    <Aside></Aside>
    <div class="main_content">
      <!-- <el-scrollbar ref="scrollbarRef" @scroll="scroll"> -->
      <div id="article_content" class="article_content">
        <div :style="!hideFooter?'':'padding-bottom:0'" class="article_box">
          <router-view v-slot="{ Component }">
            <keep-alive include="articleList,archives,images">
              <component :is="Component" />
            </keep-alive>
          </router-view>
          <footer v-if="!hideFooter" class="app-footer">
            <div class="footer-box">
              <a class="beian" href="https://beian.miit.gov.cn/" target="_blank"
                >蜀ICP备2022030856号-1</a
              >
              <span>sprinkle</span>
            </div>
          </footer>
        </div>
        <div class="article_option">
          <div class="right_nav">
            <ul>
              <li
                @click="changeactive(0)"
                data-index="0"
                :class="active == 0 ? 'active' : ''"
              >
                <a>
                  <div class="nav_item">
                    <i class="iconfont icon-like"></i>
                  </div>
                </a>
              </li>
              <li
                @click="changeactive(1)"
                data-index="1"
                :class="active == 1 ? 'active' : ''"
              >
                <a>
                  <div class="nav_item">
                    <i class="iconfont icon-message"></i>
                  </div>
                </a>
              </li>
              <li
                @click="changeactive(2)"
                data-index="2"
                :class="active == 2 ? 'active' : ''"
              >
                <a>
                  <div class="nav_item">
                    <i class="iconfont icon-gift"></i>
                  </div>
                </a>
              </li>
              <span class="active_bar"></span>
            </ul>
            <div class="ri_atc_li">
              <h5>热门文章</h5>
              <ul>
                <el-skeleton :loading="hotloading" animated :count="2">
                  <template v-for="item in hotList" :key="item.id">
                    <router-link :to="{ path: '/detail/' + item.id }">
                      <li>
                        <a class="hot_icon">
                          <img
                            class="hot_icon"
                            :src="
                              'https://sprinkle-1300857039.cos.ap-chengdu.myqcloud.com/image/hot (' +
                              ((item.id % 11) + 1) +
                              ').png'
                            "
                            alt=""
                          />
                        </a>
                        <div class="hot_title">
                          <h4>{{ item.title }}</h4>
                          <small>
                            <i class="iconfont icon-eye"></i>
                            <span>{{ item.article_view }}</span>
                          </small>
                        </div>
                      </li>
                    </router-link>
                  </template>
                </el-skeleton>
              </ul>
            </div>
          </div>
          <!-- 文章目录 -->
          <div v-if="dirtree.length" class="ri_atc_li dir_info">
            <h5>目录</h5>
            <ul class="ulcontent" v-for="item in dirtree" :key="item.id">
              <li
                :class="dirId == item.domId ? 'dirAct' : ''"
                @click="dirScroll(item.domId)"
              >
                <span>{{ item.title }}</span>
              </li>
              <ul v-if="item.children">
                <ul
                  class="sec_size"
                  v-for="citem in item.children"
                  :key="citem.id"
                >
                  <li
                    :class="dirId == citem.domId ? 'dirAct' : ''"
                    @click="dirScroll(citem.domId)"
                  >
                    <span>{{ citem.title }}</span>
                  </li>
                  <ul class="thr_size" v-if="citem.children">
                    <li
                      :class="dirId == sitem.domId ? 'dirAct' : ''"
                      v-for="sitem in citem.children"
                      :key="sitem.id"
                      @click="dirScroll(sitem.domId)"
                    >
                      <span>{{ sitem.title }}</span>
                    </li>
                  </ul>
                </ul>
              </ul>
            </ul>
          </div>
          <template v-else>
            <!-- 博客信息 -->
            <div class="ri_atc_li blog_info">
              <h5>博客信息</h5>
              <ul>
                <el-skeleton :loading="infoloading" animated :count="2">
                  <li>
                    <i class="iconfont icon-suggest"></i>
                    <span>文章数目</span>
                    <div class="blog_info_view badge">
                      {{ blogInfo.count }}
                    </div>
                  </li>
                  <li>
                    <i class="iconfont icon-comments"></i>
                    <span>访问次数</span>
                    <div class="blog_info_view badge">{{ blogInfo.views }}</div>
                  </li>
                  <li>
                    <i class="iconfont icon-calendar"></i>
                    <span>运行天数</span>
                    <div class="blog_info_view badge">{{ blogInfo.day }}</div>
                  </li>
                  <li>
                    <i class="iconfont icon-history"></i>
                    <span>最后活跃</span>
                    <div class="blog_info_view badge">
                      {{ blogInfo.time }}
                    </div>
                  </li>
                </el-skeleton>
              </ul>
            </div>
            <!-- 标签 -->
            <div v-if="!hideFooter" class="ri_atc_li blog_info">
              <h5>标签云</h5>
              <div>
                <el-skeleton :loading="tagloading" animated :count="2">
                  <!-- <router-link :to="{ path: '/detail/' + item.id }">
                    {{ item.title }}
                  </router-link> -->
                  <router-link
                    :to="{ path: '/category/' + item.category }"
                    v-for="item in tagList"
                    :key="item.category"
                    class="badge tags"
                    >{{ item.category }}</router-link
                  >
                </el-skeleton>
              </div>
            </div>
          </template>
        </div>
        <!-- <div class="clearfix"></div> -->
      </div>
      <!-- </el-scrollbar> -->
    </div>
  </div>
  <div>
    <el-backtop :bottom="90" :right="10">
      <div class="back_top">
        <i style="font-size: 32px" class="iconfont icon-fanhuidingbu"></i>
      </div>
    </el-backtop>
  </div>
  <div class="theme_btn" @click="toggleTheme">
    <i
      v-if="$store.state.theme === 'light'"
      style="font-size: 32px"
      class="iconfont icon-rijian"
    ></i>
    <i v-else style="font-size: 32px" class="iconfont icon-yueliang1"></i>
  </div>
</template>
<script>
import Header from "@/components/header/app-header.vue";
import Aside from "@/components/aside/appAside.vue";
import { useGet, usePost } from "@/hooks/index";
import { getLStorage, setLStorage } from "@/hooks/storage";
import {
  urlForGetWeather,
  urlForGetHot,
  urlForGetTags,
  urlForGetBlogInfo,
} from "@/api/url";
const get = useGet(),
  post = usePost();
export default {
  components: {
    Header,
    Aside,
  },
  data() {
    return {
      blogInfo: {},
      hotList: [],
      tagList: [],
      active: 0,
      city: "成都市",
      temperature: "20",
      weather: "多云",
      weatherBg:
        "https://sprinkle-1300857039.cos.ap-chengdu.myqcloud.com/upload/weatherbg1.png",
      hotloading: true,
      infoloading: true,
      tagloading: true,
    };
  },
  methods: {
    toggleTheme(e) {
      const newTheme = this.$store.state.theme === "light" ? "day" : "light";
      if (!document.startViewTransition) {
        this.$store.commit("setThemeMode", newTheme);
        localStorage.setItem("themeMode", newTheme); // 保存到本地存储
        let elem = document.getElementsByTagName("html")[0];
        if (newTheme == "light") {
          elem.className = "dark";
        } else {
          elem.className = "";
        }
        return;
      }
      //
      // 获取到 transition API 实例
      const transition = document.startViewTransition(() => {
        this.$store.commit("setThemeMode", newTheme);
        localStorage.setItem("themeMode", newTheme); // 保存到本地存储
        document.documentElement.classList.toggle("dark");
      });

      // 在 transition.ready 的 Promise 完成后，执行自定义动画
      transition.ready.then(() => {
        // 由于我们要从鼠标点击的位置开始做动画，所以我们需要先获取到鼠标的位置
        const { clientX, clientY } = e;

        // 计算半径，以鼠标点击的位置为圆心，到四个角的距离中最大的那个作为半径
        const radius = Math.hypot(
          Math.max(clientX, innerWidth - clientX),
          Math.max(clientY, innerHeight - clientY)
        );
        const clipPath = [
          `circle(0% at ${clientX}px ${clientY}px)`,
          `circle(${radius}px at ${clientX}px ${clientY}px)`,
        ];
        const isDark = this.$store.state.theme === "light" ? true : false;
        console.log("isdark", isDark, clipPath);
        // 自定义动画
        document.documentElement.animate(
          {
            // 如果要切换到暗色主题，我们在过渡的时候从半径 100% 的圆开始，到 0% 的圆结束
            clipPath: isDark ? clipPath.reverse() : clipPath,
          },
          {
            duration: 500,
            // 如果要切换到暗色主题，我们应该裁剪 view-transition-old(root) 的内容
            pseudoElement: isDark
              ? "::view-transition-old(root)"
              : "::view-transition-new(root)",
          }
        );
      });
    },
    initializeThemeFromLocalStorage() {
      const savedTheme = localStorage.getItem("themeMode")
        ? localStorage.getItem("themeMode")
        : "day";
      if (savedTheme) {
        this.$store.commit("setThemeMode", savedTheme);
      }
      
      if(this.isDeepLight && savedTheme === 'day'){
          setTimeout(() => {
              localStorage.setItem('themeMode','light')
              this.toggleTheme({ clientX:0, clientY:0 })
              this.$message.warning({
                message: "夜深了，早点休息吧！",
              });
          }, 1000);
          
          
      }
    },
    getWeather: function () {
      get({
        url: urlForGetWeather,
      }).then((res) => {
        // console.log("res", res);
        if (res.code === 200) {
          if (!res.data?.lives?.[0]) return;
          let data = res.data?.lives?.[0];
          if (data.city) {
            setLStorage("city", data.city);
            setLStorage("temperature", data.temperature);
            setLStorage(
              "weather",
              data.weather.replace("/", "a").replace("-", "a")
            );
          }
        } else {
          return;
        }
      });
    },

    changeactive: function (e) {
      if (e === this.active) return;
      this.active = e;
    },
    getHot: function () {
      get({
        url: urlForGetHot,
      })
        .then((data) => {
          // console.log(data)
          this.hotList = data.data || [];
          this.hotloading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTags: function () {
      get({
        url: urlForGetTags,
      })
        .then((data) => {
          // console.log(data)
          this.tagList = data.data || [];
          this.tagloading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBlogInfo: function () {
      post({
        url: urlForGetBlogInfo,
      })
        .then((data) => {
          // console.log('bloginfo',data)
          this.blogInfo = data.data;
          this.infoloading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dirScroll: function (id) {
      let parent = document.getElementById("article_content");

      this.$store.commit("updataIsDirClick", true);
      this.$store.commit("updataDirId", id);
      let target = document.getElementById(id);
      // console.log(this)
      // this.$refs.scrollbarRef.setScrollTop(target.offsetTop - parent.offsetTop)
      window.scrollTo({
        left: 0,
        top: target.offsetTop - parent.offsetTop,
        behavior: "smooth",
      });
    },
    // scroll:function(e){

    //   //判断是否在详情页
    //   if(!this.$route.path.includes('/detail')){
    //     return;
    //   }
    //   // console.log(document.getElementsByClassName('dir_info')[0].offsetParent.scrollTop)
    //   // console.log('滚动',e.scrollTop)
    //   //是-根据滚动的距离推算所对应的目录

    // }
  },
  mounted() {
    //
    this.initializeThemeFromLocalStorage();
    // new Promise((resolve,reject)=>{
    //   abc()
    // })
    let date = new Date();
    this.city = getLStorage("city") || "成都市";
    this.temperature = getLStorage("temperature") || "25";
    this.weather = getLStorage("weather") || "多云";
    this.weatherBg =
      "https://sprinkle-1300857039.cos.ap-chengdu.myqcloud.com/upload/weatherbg" +
      Math.ceil(Math.random() * 5) +
      ".png";
    if (!getLStorage("city") || date.getSeconds() % 3 < 2) {
      this.getWeather();
    }
    this.getHot();
    this.getTags();
    this.getBlogInfo();
  },
  computed: {
    hideFooter() {
      return this.$route.meta.hideFooter;
    },
    isDeepLight(){
      const now = new Date();
      const currentHour = now.getHours() ;
      // 判断当前时间是否位于下午十一点到早上五点之间
      const isNightTime = (currentHour >= 23 || currentHour < 5);
      return isNightTime
    },
    dirtree() {
      return this.$store.state.dirTreeList;
    },
    dirId() {
      return this.$store.state.dirId;
    },
  },
};
</script>

<style lang="scss" scoped>
.main_container {
  // width: 1170px;
  height: 100%;
  margin: auto;
  // padding-top: 50px;
  // min-height: 100%;
  box-shadow: 0 0 4px 3px rgb(0 0 0 / 5%);
  .main_content {
    width: auto;
    height: 100%;
    // min-height: 100%;
    margin-left: 220px;
    position: relative;
    padding-top: 50px;
    .article_content {
      width: auto;
      display: flex;
      min-height: 100%;
      position: relative;
      // overflow: scroll;
      // height: fit-content;
      background-color: var(--main-bg);
      .article_box {
        // width: 710px;
        // height: fit-content;
        // float: left;
        // padding-right: 240px;
        padding-bottom: 30px;
        position: relative;
        background-color: var(--bgcolor);
        .app-footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          // height: 60px;
          .footer-box {
            background-color: var(--main-bg);
            -webkit-box-shadow: 0 1px 3px var(--boxshadow);
            box-shadow: 0 1px 3px var(--boxshadow);
            margin: 10px;
            border-radius: 5px;
            padding: 10px;
            font-size: 13px;
            color: #777;
            .beian {
              float: right;
            }
            .beian:hover {
              color: #333;
            }
          }
        }
      }
      .article_option {
        width: 240px;
        // float: right;
        // height: 100%;
        // position: absolute;
        // right: 0;
        // top: 0;
        background-color: var(--main-bg);
        ul {
          display: flex;
          position: relative;
          padding: 0;
          margin: 0;
          box-shadow: 3px 0 5px rgb(0 0 0 / 5%), 0 0 0 rgb(0 0 0 / 5%);
          li {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
              padding: 10px 15px;
              .nav_item {
                padding: 10px;
              }
            }
          }
          li[data-index="0"].active ~ .active_bar {
            transform: translateX(55%);
          }
          li[data-index="1"].active ~ .active_bar {
            transform: translateX(255%);
          }
          li[data-index="2"].active ~ .active_bar {
            transform: translateX(460%);
          }
          .active_bar {
            content: "";
            position: absolute;
            top: auto;
            bottom: 0;
            height: 2px;
            width: 16.5%;
            background-color: var(--titlecolor);
            left: 0;
            transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
        }
        .ri_atc_li {
          opacity: 1;
          padding: 20px;
          h5 {
            font-size: 16px;
            margin-top: 0;
            margin-bottom: 10px;
            color: #777;
            font-weight: 400;
          }
          ul {
            flex-direction: column;
            box-shadow: none;
          }
          li {
            justify-content: start;
            padding: 10px 0;
            a {
              padding: 0;
            }
          }
          .hot_icon {
            display: inline-block;
            width: 40px;
            margin-right: 15px;
            border-radius: 20%;
          }
          .hot_title {
            display: block;
            overflow: hidden;
            h4 {
              font-size: 14px;
              opacity: 0.8;
              font-weight: 400;
              margin: 0;
              color: #777;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: break-word;
              white-space: normal !important;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            small {
              color: var(--normaltextcolor);
              margin-top: 10px;
              font-size: 12px;
              display: flex;
              align-items: center;
              i {
                margin-right: 5px;
              }
            }
          }
        }
        .blog_info {
          opacity: 0.8;
          color: #777;
          ul {
            box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
            li {
              display: block;
              background-color: var(--bloginfo);
              padding: 15px;
              i {
                float: left;
                margin-right: 5px;
              }
              .blog_info_view {
                float: right;
              }
            }
          }
          li:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
          li:last-child {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          .badge {
            font-size: 12px;
            background-color: var(--tagbg);
            text-shadow: 0 1px 0 rgb(0 0 0 / 20%);
            padding: 3px 7px;
            font-weight: 700;
            color: var(--tagtext);
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            border-radius: 10px;
          }
          .tags {
            display: inline-block;
            margin: 0 4px 8px 0;
          }
        }
        .dir_info {
          position: sticky;
          top: 10px;
          opacity: 0.8;
          color: #777;

          ul.ulcontent {
            background: var(--bloginfo);
            box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
            li {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: block;
              // background-color: var(--bloginfo);
              padding: 5px;
              margin: 2px 10px;
              i {
                float: left;
                margin-right: 5px;
                margin-top: 2px;
              }
              .blog_info_view {
                float: right;
              }
            }
            li.dirAct {
              background-color: var(--boxshadow);
            }
            .sec_size {
              font-size: 13px;
              text-indent: 16px;
            }
            .thr_size {
              font-size: 12px;
              text-indent: 28px;
            }
          }

          ul li:hover {
            background-color: var(--boxshadow);
            cursor: pointer;
          }
        }
        .dir_info > :nth-child(2) {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding-top: 8px;
        }
        .dir_info > :last-child {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          padding-bottom: 8px;
        }
      }
      // .clearfix {
      //   clear: both;
      // }
    }
  }
}

.back_top {
  z-index: 99;
  width: 40px;
  height: 40px;
  background-color: var(--fixtop) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: -3px 0 2px 0 rgba(0, 0, 0, 0.02);
  color: #58666e !important;
}
.theme_btn {
  position: fixed;
  right: 10px;
  bottom: 135px;
  z-index: 99;
  width: 40px;
  height: 40px;
  background-color: var(--fixoption);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: -3px 0 2px 0 rgba(0, 0, 0, 0.02);
  color: #58666e !important;
}
:deep(.el-skeleton__item) {
  background: linear-gradient(
    90deg,
    var(--skelebg) 25%,
    var(--skeletobg) 37%,
    var(--skelebg) 63%
  ) !important;
}
:deep(.el-backtop) {
  background-color: rgba(54, 55, 56, 0.2) !important;
}
</style>
