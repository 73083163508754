// ispassword
import {
    urlForAiTalk
} from '@/api/url'
const baseIp = process.env.VUE_APP_BASE_API;
  
const fetchAiStream = async function (uuid, content,signal) {
    try{
        let data = {
            "uuid":uuid,
            "messages": content,
            "max_tokens": 2048,
            "response_format": {
                "type": "text"
            },
            "temperature": 1.3,
        };/*  */
        
        const response = await fetch(baseIp + urlForAiTalk, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            signal
        });
        
        if(!response.ok){
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        return response.body; 
    }catch(error){
        console.log('请求错误：',error);
    }
    
}
export {
    fetchAiStream
}