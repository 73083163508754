<template>
  <articleHead :info="{ title: 'AI对话' }" :isArticle="false" />
  <div class="chat_container">
    <div class="chat_box">
      <div class="chat_scroll" ref="scrollContainer">
        <template v-for="(item, index) in chatList" :key="index">
          <div v-if="item.role === 'user'" class="chat_item user_chat">
            <div class="chat_info">{{ item.content }}</div>
          </div>
          <div v-else class="chat_item ai_chat">
            <div class="chat_info ai_info">
              <div style="line-height: 2em;" v-if="isThinkLoading && index === chatList.length-1">思考中...</div>
              <markDown v-else :md="item.content" />
            </div>
            <div v-show="isChating && index === chatList.length-1" class="chating_info">
              <!-- <span  class="time">思考中...</span> -->
              <svg   version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="24px" height="20px" viewBox="0 0 24 26" style="display:inline-block;margin:2px 0;enable-background:new 0 0 50 50;" xml:space="preserve">
                <rect x="0" y="6" width="4" height="6" fill="#333" opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="1s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="1s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="1s" repeatCount="indefinite" />
                </rect>
                <rect x="8" y="6" width="4" height="6" fill="#333"  opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="1s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="1s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="1s" repeatCount="indefinite" />
                </rect>
                <rect x="16" y="6" width="4" height="6" fill="#333"  opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="1s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="1s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="1s" repeatCount="indefinite" />
                </rect>
                <rect x="24" y="6" width="4" height="6" fill="#333"  opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="1s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="1s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="1s" repeatCount="indefinite" />
                </rect>
              </svg>
            </div>
          </div>
        </template>
      </div>
      
      <div v-show="isChating" @click="abortRequest" class="chat_stop"> 
        <i
          style="font-size: 26px;margin-right: 5px;"
          class="iconfont icon-stop"
        ></i>停止</div>
    </div>
    <div class="chat_input">
      <div class="chat_input_box">
        <el-input
          v-model="que"
          autosize
          maxlength="500"
          autofocus
          show-word-limit
          resize="none"
          type="textarea"
          @keyup.enter="sendchat"
          placeholder="请输入内容"
          rows="1"
        ></el-input>
        <el-tooltip  effect="dark" :content="banTipText" placement="top">
          <i
            style="font-size: 32px"
            @click="sendchat"
            :class="['iconfont', (!canSendChat)?'icon-send-no':'icon-send-active']"
          ></i>
        </el-tooltip>
        
      </div>
    </div>
  </div>
</template>
<script>
import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/agate.css";
import markDown from "@/components/markDown/markDown.vue";
import { fetchAiStream, } from "@/hooks/aichat";
import articleHead from "@/components/articleHead/articleHead.vue";
import {getVisitorId} from "@/utils/fingerprint.js"
import { usePost } from "@/hooks/index";
const post = usePost();
import {
  urlForGetChatTimes,
  urlForPostChatTimesUse
} from "@/api/url";

export default {
  name: "chat",
  components: {
    articleHead,
    markDown,
  },
  data() {
    return {
      chatTimes:0,
      initLoading:false,
      fingerprint:'',
      //回答中状态
      isChating: false,
      // 思考状态
      isThinkLoading: false,
      //聊天内容
      chatList: [
        {
          role: "tips",
          content: `<h3 style="background:none">✨ 你好！欢迎来到这里，我是你的人工智能问答助手 ✨</h5></br><h3 style="background:none">🪐 你可以在下方输入框中输入你想了解的内容或问题，点击发送就可以开始和我聊天啦！🪐</h3>`,
        },
      ],
      //输入框问题
      que: "",
      //回答数据临时保存
      data: "",
      //请求控制
      controller: null,
      //多轮对话传递参数
      chatHistory: [],
      //多轮对话轮次限制
      historyLimit: 10,
    };
  },
  mounted() {
    this.getChatTimes()
  },
  methods: {
    useChatTimes(){
      this.chatTimes -= 1
      post({
        url: urlForPostChatTimesUse,
        data:{fingerprint:this.fingerprint}
      })
        .then((data) => {
          console.log('剩余次数',data)
        })
        .catch((err) => {
        });
    },
    async getChatTimes(){
      this.initLoading = true;
      this.fingerprint = await getVisitorId()
      post({
        url: urlForGetChatTimes,
        data:{fingerprint:this.fingerprint}
      })
        .then((data) => {
          this.initLoading = false;
          this.chatTimes = data.data||0
          console.log('次数',data)
        })
        .catch((err) => {
          this.chatTimes = 0
          this.initLoading = false;
        });
    },
    //md解析
    mdRender: function () {
      const render = new marked.Renderer();
      marked.setOptions({
        renderer: render,
        highlight: function (code) {
          return hljs.highlightAuto(code).value;
        },
        gfm: true,
        tables: true,
        breaks: false,
        pedantic: false,
        sanitize: false,
        smartLists: true,
        smartypants: false,
      });
    },
    //滚动到底部 - 回答中时检测向上滑动屏幕时禁用滚到底部
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    //发起对话
    sendchat(event) {
      event.preventDefault();
      if(!this.canSendChat || this.que.trim().length === 0){
        return;
      }
      //超出多轮对话轮次限制时移除最早的对话
      if (this.chatHistory.length >= this.historyLimit) {
        this.chatHistory.shift();
      }
      if(this.chatList[0]&&this.chatList[0].role === 'tips'){
        this.chatList.shift()
      }
      this.chatList.push({
        role: "user",
        content: this.que.trim(),
      });

      this.chatHistory.push({
        role: "user",
        content: this.que.trim(),
      });

      this.scrollToBottom();
      this.useChatTimes()
      this.fetchData(null, this.chatHistory);
      this.que = "";
    },
    async fetchData(
        // 传递额外参数
      uuid = "",
      messages = [{ role: "user", content: "你好" }]
    ) {
      const index = this.chatList.length;
      this.mdRender();
      try {
        if (!this.chatList[index]) {
          this.chatList.push({
            role: "assistant",
            content: "",
          });
        }
        this.isThinkLoading = true;
        this.isChating = true;

        this.controller = new AbortController();
        const signal = this.controller.signal;

        const stream = await fetchAiStream(uuid, messages, signal);
        const reader = stream.getReader();

        const decoder = new TextDecoder("utf-8");

        
        //代码区域
        let isCodeArea = false;

        let codeStr = "";
        let count = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          try{
            const message = decoder.decode(value, { stream: true });
            this.isThinkLoading = false;
            // console.log("message", message, isCodeArea, count);
            if (message.trim() === "```") {
              if (!isCodeArea) {
                // 匹配到代码区域开始的标志
                count = 0;
                codeStr = codeStr + message;
                isCodeArea = true;
                this.data += codeStr;
              } else {
                this.data += message;
                this.chatList[index].content = marked(this.data).replace(
                  /<pre>/g,
                  `<pre  class='hljs'>`
                );
                count = 0;
                isCodeArea = false;
                codeStr = "";
                this.scrollToBottom();
              }
            } else {
              if (isCodeArea) {
                count++;
                // codeStr = codeStr + message;
                this.data += message;
                if (count === 1) {
                  this.chatList[index].content += `<pre class='hljs'><code>${message}`;
                } else {
                  this.chatList[index].content += `${message}`;
                }
                this.scrollToBottom();
              } else {
                this.data += message;
                this.chatList[index].content = marked(this.data).replace(
                  /<pre>/g,
                  `<pre  class='hljs'>`
                );
                this.scrollToBottom();
              }
            }
          }catch(err){
            this.isThinkLoading = false;
            this.isChating = false;
            this.chatList[index].content = '当前服务器或网络异常，已通知管理员处理，请稍后再试！'
            this.data = "";
          }
        }
        // 最后一次解码，确保所有数据都被处理
        this.data += decoder.decode();

        if (this.chatHistory.length >= this.historyLimit) {
          this.chatHistory.shift();
        }
        this.chatHistory.push({
          role: "assistant",
          content: this.data,
        });
        this.isChating = false;
        this.data = "";
      } catch (error) {
        console.log('发生错误',error)
        this.isThinkLoading = false;
        this.isChating = false;
        if (error.name === "AbortError") {
          console.log("Request aborted",this.data);
        } else {
          this.chatList[index].content = '当前服务器或网络异常，已通知管理员处理，请稍后再试！'
          console.error("Error fetching data:", error);
        }
        this.data = "";
      } finally {
        this.controller = null;
      }
    },
    //提问建议
    suggest() {
    },
    abortRequest() {
      if (this.controller) {
        this.controller.abort();
      }
    },
  },
  computed: {
    banTipText(){
      if(this.initLoading){
        return "正在准备中，请稍候..."
      }else if(this.isThinkLoading){
        return "正在思考中，请稍等..."
      }else if(this.isChating){
        return "正在回复中，请稍等..."
      }else if(this.chatTimes < 1){
        return "今日问答次数已用完，如有更多需要请联系管理员"
      }else{
        return "发送"
      }
    },
    canSendChat() {
      return !(this.initLoading||this.isThinkLoading || this.isChating || this.chatTimes < 1);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep #article_md {
  padding: 0 !important;
  box-shadow: none !important;
}
.article_content {
  max-height: calc(100% - 50px);
  overflow-y: hidden;
}
.chat_container {
  height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.chat_box {
  position: relative;
  flex: 1;
  height: 100%;
  overflow-y: hidden;
  padding: 20px;
  .chat_stop{
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    padding:  5px;
    font-size: 14px;
    border-radius: 7px;
    background-color: var(--main-bg);
    color: var(--mdtextcolor);
    cursor: pointer;
  }
  .chat_scroll {
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE 和 Edge */
    scrollbar-width: none; /* Firefox */
  }
  .chat_scroll::-webkit-scrollbar {
    display: none;
  }
  .chat_item {
    display: flex;
    flex-direction: column;
    padding: 9px 0;
    .chat_info {
      width: fit-content;
      display: inline-block;
      padding: 9px 14px;
      background: var(--main-bg);
      border-radius: 10px;
      max-width: 80%;
      color: var(--mdtextcolor);
    }
    .ai_info {
      max-width: 95%;
    }
  }
  .user_chat {
    display: flex;
    flex-direction: row-reverse;
  }
  .ai_chat{
    justify-content: center;
  }
}
.chat_input {
  padding: 0 10px;
  margin-bottom: 22px;
  .chat_input_box {
    padding: 12px;
    border-radius: 14px;
    background-color: var(--main-bg);
    box-shadow: 0 6px 10px 0 rgba(42, 60, 79, 0.1);
    display: flex;
    align-items: flex-end;
    :deep textarea {
      border: none;
      box-shadow: none;
      background-color: var(--main-bg);
    }
    :deep .el-input__count {
      background-color: var(--main-bg);
    }
  }
}

svg path,
svg rect{
  fill: #777;
}
.icon-send-no{
  cursor: not-allowed;
  color:#bfbfbf
}
.icon-send-active{
  cursor: pointer;
  color: var(--mdtextcolor);
}
.chating_info{
  display: inline-block;
  margin-top: 5px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 7px;
  width: fit-content;
  background-color: var(--main-bg);
  color: var(--mdtextcolor);
}
</style>
