const send = function (type, val) {
    if (type === 'Requst') {
        const data = JSON.stringify(val)
        new Image().src = `${process.env.VUE_APP_BASE_API}getSendData.gif?type=${type}&data=${encodeURIComponent(data)}`
    } else {
        if (navigator.sendBeacon) {
            let formData = new FormData();
            formData.append('type', type)
            
            if (type === 'PageStay') {
                val = val[0]
            }
            
            if (Array.isArray(val)) {
                val.forEach(item => {
                    Object.keys(item).forEach(key => {
                        formData.append(key, item[key]);
                    });
                });
            } else {
                Object.keys(val).forEach(key => {
                    formData.append(key, val[key]);
                });
            }
            navigator.sendBeacon(`${process.env.VUE_APP_BASE_API}SendDataBeacon`, formData)
        } else {
            const data = JSON.stringify(val)
            new Image().src = `${process.env.VUE_APP_BASE_API}getSendData.gif?type=${type}&data=${encodeURIComponent(data)}`
        }
    }



}
export {
    send
}