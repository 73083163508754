export function reportPagePerformance(app) {
    // if (process.env.NODE_ENV === "development") return;

    const send = app.config.globalProperties.$send;

    // 获取页面加载性能数据
    const getPageLoadPerformance = () => {

        const performanceEntries = performance.getEntriesByType('navigation')[0];
        console.log('页面指标',performanceEntries)
        if (!performanceEntries) return null;

        return {
            // 页面加载完成的时间
            loadEventEnd: performanceEntries.loadEventEnd.toFixed(3),
            
            // DOM 解析完成的时间
            domContentLoadedEventEnd: performanceEntries.domContentLoadedEventEnd.toFixed(3),
            // DOM 构建完成时间
            domComplete: performanceEntries.domComplete.toFixed(3),
            //页面变成可交互状态的时间
            domInteractive: performanceEntries.domInteractive.toFixed(3),
            //首次内容绘制的时间
            firstContentfulPaint: performance.getEntriesByName('first-contentful-paint')[0] ?.startTime.toFixed(3),
            // 首次绘制的时间，即浏览器开始绘制任何内容的时间
            firstPaint: performance.getEntriesByName('first-paint')[0] ?.startTime.toFixed(3),
            // 页面加载时间
            duration: performanceEntries.duration.toFixed(3),
            // 导航类型，例如 navigate、reload、back_forward 等
            type: performanceEntries.type
        };
    };

    // 监听资源加载完成事件
    document.addEventListener('DOMContentLoaded', () => {
        let entries = performance.getEntriesByType('resource');
        entries = entries.filter(entry => ['link', 'script'].includes(entry.initiatorType));
        console.log('资源指标',entries)
        if (entries.length) {
            send('ResourcePerformance', entries.map(entry => ({
                //资源的 URL
                name: entry.name,
                // 资源加载的总时间
                duration: entry.duration.toFixed(3),
                // 资源的发起类型，例如 script、img、css 等
                initiatorType: entry.initiatorType,
                // 资源传输的大小，包括响应头和响应体
                transferSize: entry.transferSize.toFixed(3),
                // 编码后的响应体大小
                encodedBodySize: entry.encodedBodySize.toFixed(3),
                // 解码后的响应体大小。
                decodedBodySize: entry.decodedBodySize.toFixed(3)
            })));
        }
    });
    

    // 监听页面加载完成事件
    const pageLoadObserver = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        if (entries.length) {
            const pageLoadPerformance = getPageLoadPerformance();

            if (pageLoadPerformance) {
                send('PageLoadPerformance', [pageLoadPerformance]);
            }
        }
    });

    pageLoadObserver.observe({
        entryTypes: ['navigation']
    });
}