import {
    createApp
} from 'vue'
import "core-js/stable";
import "regenerator-runtime/runtime";
import App from './App.vue'
import "@/styles/main.scss";
import "@/styles/sprinkle.css";
import router from "./router";
import store from "./store"

import {
    pageStay
} from "./utils/pageStay"
import {
    errMonitor
} from "./utils/errorMonitor"
import {
    reportPagePerformance
} from './utils/performance';
// import ElementUI from 'element-ui'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import vuetyped from 'vue3typed'
import 'animate.css'

import {getVisitorId} from "@/utils/fingerprint.js"
import {
    send
} from '@/hooks/datareport'
import 'animate.css/animate.compat.css'

import './utils/router'
import './mock/article/index'
getVisitorId()
let theme = localStorage.getItem('themeMode') ? localStorage.getItem('themeMode') : 'day'
let elem = document.getElementsByTagName('html')[0]
if (theme === 'light') {
    elem.className = 'dark'
} else {
    elem.className = ''
}

const app = createApp(App);
app.config.globalProperties.$send = send
reportPagePerformance(app);
app.use(store).use(router).use(vuetyped).use(ElementPlus)
    .use(pageStay(app)).use(errMonitor(app))


app.mount('#app')

export default app